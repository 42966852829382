html,
body {
  height: 100%;
  background: #fff;
}

body {
  padding: 60px 0 0 0;
  font-family: "Roboto", sans-serif;
}

.appWrapper {
  min-height: 100%;
  padding-bottom: 15px;
}

.middleWrapper {
  padding: 0 15px;
}

.formField {
  margin-bottom: 20px;
}

.form-control,
.form-select {
  border: 0;
  border-bottom: 1px solid #dfdfe4;
  border-radius: 0;
  letter-spacing: 0.86px;
  font-size: 18px;
  background-color: transparent !important;
  padding: 10px 0;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  /* color: #2c2c2c; */
  color: #97a0ee;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  /* color: #2c2c2c; */
  color: #97a0ee;
}

.form-control::placeholder {
  /* color: #2c2c2c; */
  color: #97a0ee;
}

.btn {
  border: 0;
  width: 172px;
  font-size: 16px;
  padding: 18px;
  border-radius: 35px;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-pri {
  background-color: #3d4ff4;
  color: #fff;
}

.btn-pri:hover {
  color: #fff;
}

.btn-sec {
  background-color: #e5e6fb;
  color: #3d4ff4;
}

.btn-sec:hover {
  color: #3d4ff4;
}

.signUpWrapper .formField {
  margin-bottom: 80px;
}

/* .emailField,
.passwordField {
    background-repeat: no-repeat;
    background-position: 10px center;
    padding: 15px 5px 15px 50px;
}

.emailField {
    background-image: url(../img/username.svg);
}

.passwordField {
    background-image: url(../img/password.svg);
} */

.footnote {
  color: #b2b2b2;
  font-size: 12px;
}

.homepage .btn-pri {
  margin-bottom: 20px;
}

.btnWrapper {
  background: #f8f7fc;
  padding: 30px 15px;
}

.searchArea {
  background: #3d4ff4;
  padding: 20px 15px;
  border-radius: 0 0 40px 40px;
}

.homepage .footnote {
  margin: 15px 0 20px 0;
}

.searchBox label {
  font-size: 20px;
  color: #fff;
  margin: 0 0 20px 0;
}

.searchBox .form-control {
  background: #fff;
  border: 0;
  color: #b2b2b2;
  padding: 15px 20px;
  border-radius: 30px;
}

.searchBox .form-control::placeholder {
  /* color: #b2b2b2; */
  color: #97a0ee;
}

.backBtn {
  width: 16px;
  height: 15px;
  background-image: url(../img/backBtnDark.svg);
  text-indent: -9999px;
  margin: 0 22px 0 0;
}

.menuBar {
  background-color: #fff;
  box-shadow: 0px 7px 15px #a5a5a512;
  height: 60px;
}

.menuBar .dropdown-toggle {
  background-image: url(../img/menuBtn.svg);
  background-size: 20px 20px;
  background-position: center center;
  padding: 10px 20px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  width: auto;
  background-color: transparent !important;
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.menuBar .dropdown-toggle::after {
  display: none;
}

.menuBar .dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto;
}

.menuBar .dropdown-menu {
  box-shadow: 0px 7px 15px #00000029;
  border: 0;
  padding: 15px 0;
}

.menuBar .dropdown-menu .dropdown-item {
  padding: 8px 20px;
  color: #373737;
  font-weight: bold;
  font-size: 13px;
}

.menuBar .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}

.menuBar .dropdown-menu .dropdown-item.active {
  background-color: #373737;
  color: #fff;
}

.navbar-brand {
  color: #3d4ff4;
  font-weight: bold;
}

.homepage .menuBar {
  background-color: #3d4ff4;
  box-shadow: none;
}

.homepage .menuBar .dropdown-toggle {
  background-image: url(../img/menuBtn-white.svg);
}

.homepage .navbar-brand {
  color: #fff;
}

.homepage .backBtn {
  background-image: url(../img/backBtn.svg);
}

.card {
  border: 0;
  box-shadow: 0px 3px 6px #00000029;
  margin: 12px 0 0 0;
}

.card h2,
.detailBox h2 {
  font-size: 24px;
  color: #373737;
  margin: 0;
}

.operationsList li {
  margin: 0 12px;
}

.operationsList li a {
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  text-indent: -9999px;
  display: block;
}

.operationsList li.location {
  background-image: url(../img/location.svg);
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  text-indent: -9999px;
  background-size: contain;
}

.operationsList li.edit {
  background-image: url(../img/edit.svg);
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  text-indent: -9999px;
  background-size: contain;
}

.operationsList li.trash {
  background-image: url(../img/trash.svg);
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  text-indent: -9999px;
  background-size: contain;
}

.editIcon {
  background-image: url(../img/edit.svg);
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  text-indent: -9999px;
  background-size: contain;
}

.addCategory .editIcon {
  margin: 0 7px 0 0;
}

.deleteIcon {
  background-image: url(../img/trash.svg);
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  text-indent: -9999px;
  background-size: contain;
}
.pointer {
  cursor: pointer;
}

.journeyPoints {
  margin: 12px 0 0 0;
}

.journeyPoints p {
  color: #373737;
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: bold;
}

.journeyPoints h3 {
  color: #3d4ff4;
  font-size: 16px;
  margin: 0;
}

.journeyPin {
  width: 82px;
  height: 18px;
  background-image: url(../img/journeyPin.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}

.journeyDates {
  margin: 15px 0 0 0;
}

.jDate {
  font-size: 12px;
  color: #373737;
  font-weight: 600;
  background-image: url(../img/calendarFilled.svg);
  background-size: 36px 36px;
  background-position: left center;
  padding: 6px 0 6px 45px;
  background-repeat: no-repeat;
}

.jDateTime {
  font-size: 12px;
  color: #373737;
  font-weight: 600;
  background-size: 36px 36px;
  background-position: left center;
  padding: 6px 0 6px 45px;
  background-repeat: no-repeat;
}

.journeyDates .jDate {
  background-size: 29px 29px;
  padding: 6px 0 6px 38px;
}

.jTime {
  font-size: 12px;
  color: #373737;
  background-image: url(../img/time.svg);
  background-size: 36px 36px;
  background-position: left center;
  padding: 0 0 0 45px;
  background-repeat: no-repeat;
}

.detailBox {
  background: #f8f7fc;
  padding: 15px;
}

.detailCard h2 {
  color: #3d4ff4;
  font-size: 16px;
}

.dateDetail {
  margin: 25px 0;
}

.stayDate {
  color: #0b9d49;
  font-size: 12px;
  font-weight: 600;
  padding: 0 0 0 28px;
  background-image: url(../img/calendar.svg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px 18px;
}

.totalDay {
  color: #2c2c2c;
  font-size: 12px;
  font-weight: 600;
  padding: 0 0 0 24px;
  background-image: url(../img/dayIcon.svg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 19px 17px;
}

.eventDetail {
  margin: 0 0 24px 0;
}

.miquaatName {
  color: #2c2c2c;
  font-size: 12px;
  font-weight: 600;
  padding: 0 0 0 28px;
  background-image: url(../img/event-schedule.svg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px 18px;
  margin: 0 0 5px 0;
}

.userDetails label {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.57px;
  color: #7b7b7b;
}

.userDetails p {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #2c2c2c;
}

.createTrip {
  margin: 30px 0 0 0;
}

.createTrip .formField {
  margin: 0 0 40px 0;
}

.cityBlock {
  padding: 0 0 40px 45px;
}

.cityBlock p {
  font-size: 12px;
  color: #373737;
  margin: 0;
  font-weight: bold;
}

.cityBlock h2 {
  color: #000000;
  font-size: 30px;
}

.cityBlock .jDate,
.cityBlock .jTime {
  font-size: 16px;
  letter-spacing: 0.43px;
}

.joiner {
  position: relative;
}

.joiner:before {
  content: "";
  height: calc(100% - 36px);
  border-left: 1px dashed #4539f4;
  width: 1px;
  top: 36px;
  left: 18px;
  position: absolute;
}

.sourceCity,
.destinationCity {
  background-repeat: no-repeat;
  background-position: top left;
}

.sourceCity {
  background-image: url(../img/sourcePin.svg);
}

.destinationCity {
  background-image: url(../img/destinationPin.svg);
}

.vehicleType {
  padding: 30px 5px;
  background-color: #f8f7fc;
}

.vehicleType ul li {
  display: block;
  width: 45px;
  height: 45px;
  margin: 0 10px;
  border-radius: 8px;
  background-position: center;
  cursor: pointer;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

.vehicleType ul li.carIcon {
  background-image: url(../img/car.svg);
}

.vehicleType ul li.carIcon:hover {
  background-image: url(../img/carActive.svg);
}

.vehicleType ul li.carIconA {
  background-image: url(../img/carActive.svg);
}

.vehicleType ul li.palneIcon {
  background-image: url(../img/plane.svg);
}

.vehicleType ul li.palneIcon:hover {
  background-image: url(../img/planeActive.svg);
}

.vehicleType ul li.palneIconA {
  background-image: url(../img/planeActive.svg);
}

.vehicleType ul li.trainIcon {
  background-image: url(../img/train.svg);
}

.vehicleType ul li.trainIcon:hover {
  background-image: url(../img/trainActive.svg);
}

.vehicleType ul li.trainIconA {
  background-image: url(../img/trainActive.svg);
}

.vehicleType ul li.busIcon {
  background-image: url(../img/bus.svg);
}

.vehicleType ul li.busIcon:hover {
  background-image: url(../img/busActive.svg);
}

.vehicleType ul li.busIconA {
  background-image: url(../img/busActive.svg);
}

.vehicleType ul li.miscIcon {
  background-image: url(../img/misc.svg);
}

.vehicleType ul li.miscIcon:hover {
  background-image: url(../img/miscActive.svg);
}

.vehicleType ul li.miscIconA {
  background-image: url(../img/miscActive.svg);
}
.actionButtons {
  padding: 30px 0;
}

.actionButtons .btn {
  margin: 0 10px;
}

.addCity .detailBox {
  margin: 0 0 30px 0;
}

.loginBox h1 {
  text-align: center;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 115px;
}

.loginBox h1 span {
  font-style: italic;
  font-weight: 700;
  display: inline-block;
  color: #3d4ff4;
}

.loginBox .form-control {
  padding: 20px 5px 20px 60px;
}
.loginBox .form-control {
  border: 1px solid #ebe6fb;
  border-radius: 15px;
  margin-bottom: 15px;
  padding: 20px 5px 20px 60px;
  color: #242134;
  font-weight: 400;
}

.loginBox .form-control::placeholder {
  color: #97a0ee;
  font-size: 16px;
  font-weight: 700;
}

.loginBox .btn {
  margin-top: 50px;
  width: 100%;

  box-shadow: 0px 4px 7px #572cd871;
  border-radius: 34px;
}

.userNameField {
  background-image: url(../img/username.svg);
  background-repeat: no-repeat;
  background-position: 20px center;
}

.passwordField {
  background-image: url(../img/password.svg);
  background-repeat: no-repeat;
  background-position: 20px center;
}

.formWrapper {
  position: relative;
  padding: 40px 0 0 0;
}
.formWrapper .error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: red;
  margin: 0;
}

.loginBox .form-check-input {
  border: 2px solid #3d4ff4;
}

.loginBox .form-check-input:checked {
  background-color: #3d4ff4;
  border-color: #3d4ff4;
}
.loginBox .btn {
  padding: 20px;
  font-weight: 700;
}

.loginBox .btn-blue {
  background: #3d4ff4;
  color: #fff;
}
.loginBox .btn-blue:hover {
  color: #fff;
}

.navbar.fixed-bottom {
  background-color: #ffffff;
  color: #3d4ff4;
  padding-left: 3.625rem;
  z-index: 5;
}
.navbar.fixed-bottom a {
  color: #0d6efd;
  text-decoration: underline;
}

.logoutBtn {
  padding: 15px 0;
}

.logoutBtn a {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url(../img/exitIcon.svg);
  background-size: cover;
  text-indent: -9999px;
}
.Toastify .Toastify__toast {
  width: 90%;
  margin: 10px auto 0;
  border-radius: 15px;
}
.loginBox .btn-blue {
  background: #3d4ff4;
  color: #fff;
}
.loginBox .btn-blue:hover {
  color: #fff;
}
/* .addmiqaatScroll{height:353px} */
.siteWrapper {
  height: 100%;
}
.timepicker {
  align-items: center;
  background-color: transparent;
  border: none !important;
  text-align: center;
}
.visibilityHide {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
}
.miqaatdropdown button {
  width: 100%;
}
.hasclose {
  position: relative;
}

.hasclose .form-control {
  padding-right: 20px;
}
.hasclose .btn-close {
  position: absolute;
  right: 0;
  bottom: 15px;
  /* z-index: 15; */
}
.table-fixed tbody {
  display: block;
  max-height: 521px;
}

.table-fixed thead,
.table-fixed tbody tr,
.table-fixed tfoot {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.miqaatColumn {
  width: 40%;
}

.dateColumn {
  width: 40%;
  text-align: end;
}
.actionColumn {
  width: 20%;
  text-align: right;
}
.css-1v99tuv{
  /* color: #97a0ee; */
  font-size: 18px;
}
.css-11unzgr{
  z-index: 1000;
}
.dateplaceholder{
  color: #97a0ee;
}
.loginfooter{
  text-align: center;
    font-size: 12px;
    margin-top: 72px;
}
.react-timekeeper{
  width: 100% !important;
}